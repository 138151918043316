import { FC, useEffect } from "react"
import { useSentry } from "@heyhabito/sentry"

import config from "../../config.json"

import { SentryErrorBoundary } from "../shared-components/Shared/ErrorBoundary"
import { BaseHead, Base } from "../shared-components/Base/Base"

import { getCookie, removeAuthCookie } from "../utils/auth0"
import { doMuveTag } from "../utils/muve-tag"
import queryString from "query-string"

interface GTMEvent {
  event: string
  eventCallback: () => void
  eventTimeout: number
}

declare global {
  interface Window {
    dataLayer?: GTMEvent[]
  }
}

export const Head: FC = () => (
  <SentryErrorBoundary>
    <BaseHead
      metaTitle="Set token"
      metaDescription=""
      canonicalUrl="https://www.habito.com/set-token"
      noIndex={true}
      pageName="set token"
      intercom={false}
      config={config}
    />
  </SentryErrorBoundary>
)

const SetToken: FC = () => {
  const TIMEOUT = 3000
  let REDIRECTING = false

  const { logToSentry } = useSentry()

  function doRedirect(): void {
    if (REDIRECTING) return

    REDIRECTING = true
    const token = getCookie("authFlows_habitoToken") || ""
    localStorage.setItem("habito/token", token)

    const redirectURI = getCookie("authFlows_loginDestination") || ""

    removeAuthCookie("authFlows_habitoToken")
    removeAuthCookie("authFlows_loginDestination")

    switch (queryString.parse(redirectURI.split("?")[1] || "").from) {
      case "muve":
        doMuveTag(token, logToSentry, () => {
          window.location.replace(redirectURI)
        })
        break
      default:
        window.location.replace(redirectURI)
    }
  }

  useEffect(() => {
    if (
      window !== undefined &&
      document !== undefined &&
      localStorage !== undefined
    ) {
      /*
      Here's something fun. We need to track the 'SignedUp' event through GTM.
      But we can't just send them an event, that would be too easy. Given that
      there is a redirect immediately after, the event is never tracked and
      marketing is very unhappy. We're now trialing 'eventCallback' with a
      global timeout to see if things improve.
    */

      // No matter what happens (ie. GTM is slow to respond and isn't invoking
      // our callback) we set a global timeout to force a redirect either way.
      setTimeout(doRedirect, TIMEOUT)

      // By default, we have nothing to track so we can just invoke the callback
      // immediately.
      let trackSignUp = function (cb: () => void): void {
        cb()
      }

      if (!window.dataLayer) {
        window.dataLayer = []
      }

      // Currently, it'll never be anything else, so this is a safe
      // operation. If the auth flow code wants more query parameters, you
      // will need to update this.
      if (window.location.search == "?new_account=true") {
        trackSignUp = function (cb) {
          if (window.dataLayer !== undefined) {
            window.dataLayer.push({
              event: "SignedUp",
              eventCallback: cb,
              eventTimeout: TIMEOUT,
            })
          }
        }
      }

      trackSignUp(doRedirect)
    }
  })

  /**
   * We necessarily don't want to use WrappedBase here.
   * WrappedBase deals with either an already authenticated user or a user who needs a token.
   * Either way that isn't relevant for the set-token page, because we're actually in the process
   * of authenticating.
   * It would also be dangerous, as the user provider can overwrite the token we're trying to set.
   **/
  return (
    <SentryErrorBoundary>
      <Base />
    </SentryErrorBoundary>
  )
}

export default SetToken
